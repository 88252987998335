// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-humains-faq-js": () => import("./../src/pages/humains/faq.js" /* webpackChunkName: "component---src-pages-humains-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-recruit-ghana-js": () => import("./../src/pages/recruit/ghana.js" /* webpackChunkName: "component---src-pages-recruit-ghana-js" */),
  "component---src-pages-recruit-index-js": () => import("./../src/pages/recruit/index.js" /* webpackChunkName: "component---src-pages-recruit-index-js" */),
  "component---src-pages-recruit-nigeria-js": () => import("./../src/pages/recruit/nigeria.js" /* webpackChunkName: "component---src-pages-recruit-nigeria-js" */),
  "component---src-pages-recruit-philippines-js": () => import("./../src/pages/recruit/philippines.js" /* webpackChunkName: "component---src-pages-recruit-philippines-js" */),
  "component---src-pages-recruit-venezuela-js": () => import("./../src/pages/recruit/venezuela.js" /* webpackChunkName: "component---src-pages-recruit-venezuela-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

